import React, { Component } from 'react'

import { PopupEditProfile } from '../../pages/admin/popups/edit-profile'

import { Popup } from './popup.styles'
class PopupContainer extends Component {
  render() {
    let popupEl
    switch(this.props.mode){
      case 'edit-profile':
        popupEl = <PopupEditProfile {...this.props} body='clear' width='800px'/>
        break
      default:
        break
    }
    return (
      <Popup id='popup'>
        {popupEl}
      </Popup>
    )
  }
}

export default PopupContainer