import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useLocation, useHistory } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { client } from '../../_includes/forstok'

import SingleLayout from '../../_layouts/single-layout'
import PopupContainer from '../../components/popup/popup.container'
import MessageComponent from '../../components/message/message.component'

import AdminPartial from './partials/admin'

const AdminIndex = () => {
  let location = useLocation(),
    history = useHistory()

  const params = new URLSearchParams(location.hash),
				hashActiveStatusTab = params.get('#tab') ? params.get('#tab') : 'profile'

  const [ isOpenPopup, setOpenPopup ] = useState(false)
  const [ isOpenPopupContainer, setOpenPopupContainer ] = useState(false)
  const [ modePopup, setModePopup ] = useState('')
  const [ dataIdPopup, setDataIdPopup ] = useState('')
  const [ dataDetailPopup, setDataDetailPopup ] = useState('')
  const [ activeTab, setActiveTab ] = useState(hashActiveStatusTab)

  const evOpenPopup = (e) => {
    const buttonEl = e.target.closest('a')
    if (!buttonEl) return
    const popup = buttonEl.getAttribute('data-popup')
    const dataId = buttonEl.getAttribute('data-id')
    const dataDetail = buttonEl.getAttribute('data-detail')

    const props = {
      mode: popup,
      id: dataId,
      detail: JSON.parse(dataDetail)
    }
    evTooglePopup(props)
  }

  const evTooglePopup = (props) => {
    if(isOpenPopup) {
      setOpenPopup(false)
      setModePopup('')
      setDataIdPopup('')
      setDataDetailPopup('')
      setOpenPopupContainer(false)
      document.getElementsByTagName("BODY")[0].classList.remove("is-immuned")
    }else {
      setOpenPopup(true)
      setModePopup(props.mode)
      setDataIdPopup(props.id)
      setDataDetailPopup(props.detail)
      setOpenPopupContainer(true)
      document.getElementsByTagName("BODY")[0].classList.add("is-immuned") 
    }
  }

  const evClickTabDetail = e => {
    e.persist()
    let label = e.target.getAttribute('data-label')
    if(!label) label = e.target.closest('div').getAttribute('data-label')
    setActiveTab(label)
    history.push({ hash: `tab=${label}` })
  }

  const evCreateMessage = props => {
    const bodyEl = document.getElementsByTagName('BODY')[0],
        messageEl = document.getElementById('message')
    if(!messageEl) {
      const el = document.createElement('div')
      el.id = 'message'
      bodyEl.append(el)
    }
    ReactDOM.unmountComponentAtNode(document.getElementById('message'))
    bodyEl.classList.add('is-immuned')
    ReactDOM.render(<MessageComponent {...props} />, document.getElementById('message'))
  }

  return (
    <ApolloProvider client={client}>
      <SingleLayout evTooglePopup={evTooglePopup}>
        <AdminPartial evOpenPopup={evOpenPopup} activeTab={activeTab} evClickTabDetail={evClickTabDetail} evCreateMessage={evCreateMessage} />
        <PopupContainer isOpen={isOpenPopup} isOpenContainer={isOpenPopupContainer} mode={modePopup} dataId={dataIdPopup} evTooglePopup={evTooglePopup} dataDetail={dataDetailPopup}/>
      </SingleLayout>
    </ApolloProvider>
  )
}

export default AdminIndex;