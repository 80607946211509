import React from 'react'
import { useQuery } from "@apollo/client"

import { GET_STATUS_LIST } from '../../../../config/graphql'
import LoadingComponent from '../../../../components/loading/loading.component'
import SelectComponent from '../../../../components/select/select.component'

export const StatusList = ({ evChangeStatus, currentStatus, errorRegister }) => {
  const { loading, error, data } = useQuery(GET_STATUS_LIST)
  if(loading) return <LoadingComponent mode='mediumclear'/>
  if(error) return `Error!: ${error}`
  let DefaultValue = { value: '', label: 'Please select' },
    StatusOptions = data ? data.profileStatuses.map(list => {
      return { value: list.id, label: list.status}
    }) : []
  StatusOptions = [DefaultValue].concat(StatusOptions)
  return (
    <SelectComponent plugin='react-select' options={StatusOptions} defaultValue={(currentStatus != null) ? { value: currentStatus.id, label: currentStatus.status } : DefaultValue} onChange={evChangeStatus} isError={(errorRegister && errorRegister['statusId'])}/>
  )
}