import styled from 'styled-components'

/* function */
const getLoadingParentContainerModifiedStyled = ({color}) => {
  let styleLoading = ''
  if(color) {
    styleLoading += `
      > div {
        background-color: ${color};
      }
    `
  }
  return styleLoading

}
const getLoadingContainerStyled = ({ mode, position, color, margin, ...props }) => {
  let stylesLoading = ''
  switch(mode){
    case 'small' : stylesLoading += `
      border-top: 2px solid ${color? color : '#fc5c64'};
      border-right: 2px solid ${color? color : '#fc5c64'};
      border-bottom: 2px solid ${color? color : '#fc5c64'};
      border-left: 2px solid transparent;
      &, &:after {
        width: 12px;
        height: 12px;
      }
    `
    break
    case 'smallclear' : stylesLoading += `
      border-top: 2px solid ${color? color : '#fc5c64'};
      border-right: 2px solid ${color? color : '#fc5c64'};
      border-bottom: 2px solid ${color? color : '#fc5c64'};
      border-left: 2px solid transparent;
      margin: 0;
      &, &:after {
        width: 12px;
        height: 12px;
      }
    `
    break
    case 'medium' : stylesLoading += `
      border-top: 3px solid ${color? color : '#fc5c64'};
      border-right: 3px solid ${color? color : '#fc5c64'};
      border-bottom: 3px solid ${color? color : '#fc5c64'};
      border-left: 3px solid transparent;
      &, &:after {
        width: 20px;
        height: 20px;
      }
    `
    break
    case 'mediumclear' : stylesLoading += `
      border-top: 3px solid ${color? color : '#fc5c64'};
      border-right: 3px solid ${color? color : '#fc5c64'};
      border-bottom: 3px solid ${color? color : '#fc5c64'};
      border-left: 3px solid transparent;
      margin: 20px;
      &, &:after {
        width: 20px;
        height: 20px;
      }
    `
    break
    case 'large' : stylesLoading += `
      border-top: 4px solid ${color? color : '#fc5c64'};
      border-right: 4px solid ${color? color : '#fc5c64'};
      border-bottom: 4px solid ${color? color : '#fc5c64'};
      border-left: 4px solid transparent;
      &, &:after {
        width: 25px;
        height: 25px;
      }
    `
    break
    default:
      
    break
  }
  if(position) {
    if(position === 'left') {
      stylesLoading += `
        margin-left: 0;
        margin-right: 0;
      `
    }
  }
  if(margin) {
    stylesLoading += `
      margin: ${margin};
    `
  }
  return stylesLoading
}
/* function */

/* component */
export const LoadingContainer = styled.div`
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  display: block;
  position: relative;
  text-indent: -9999px;
  margin: 10px auto;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  &, &:after {
    border-radius: 50%;
  }
  ${getLoadingContainerStyled}
  &.is-hidden {
    display: none;
  }
`
export const LoadingParentContainer = styled.div `
  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
  width: auto;
  display: inline;
  margin-left: 4px;
  > div {
    width: 5px;
    height: 5px;
    background-color: #FF5A5A;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    margin-left: 1px;
    &:nth-child(1) {
      animation-delay: -0.32s;
    }
    &:nth-child(2) {
      animation-delay: -0.16s;
    }
    &:nth-child(3) {
      animation-delay: -0.32s;
    }
  }
  ${getLoadingParentContainerModifiedStyled}
  
`
/* component */