import gql from 'graphql-tag'

export const GET_PROFILE_LIST = gql`
  query profiles {
	  profiles {
      id
      name
      email
      totalAccounts
      company {
        id
        name
      }
      type {
        id
        name 
      }
      status {
        id
        status
      }
	  }
  }
`
export const GET_TYPE_LIST = gql`
  query profileTypes {
    profileTypes {
      id
      name
    }
  }
`
export const GET_STATUS_LIST = gql`
  query profileStatuses {
    profileStatuses {
      id
      status
    }
  }
`
export const GET_COMPANY_LIST = gql`
  query companies($search: String, $limit: Int) {
    companies (search: $search, limit: $limit){
      id
      name
    }
  }
`
export const QUERY_GET_ANNOUNCEMENT_LIST = gql`
  query announcement {
    announcement {
      message
      updatedAt
    }
  }
`

export const MUTATION_CREATE_COMPANY = gql`
  mutation createCompany($input: CreateCompanyInput!) {
    createCompany (input: $input) {
      company {
        id
      }
    }
  }
`

export const MUTATION_UPDATE_PROFILE = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile (input: $input) {
      profile {
        id
        name
      }
    }
  }
`

export const MUTATION_SET_ANNOUNCEMENT = gql`
  mutation setAnnouncement($input: SetAnnouncementInput!) {
    setAnnouncement (input: $input) {
      announcement {
        message
        updatedAt
      }
    }
  }
`