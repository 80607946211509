import React from 'react'
import PropTypes from 'prop-types'

import IconComponent from '../icon/icon.component'

import { ErrorContainer } from './error.styles'

const ErrorComponent = ({ children, mode, ...props }) => {
  return (
    <ErrorContainer mode={mode} {...props}>
      <IconComponent name='alert' width='10px'></IconComponent>
      {children}
    </ErrorContainer>
  )
}

ErrorComponent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorComponent