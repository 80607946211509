import React from 'react'
import PropTypes from 'prop-types'

import { LoadingContainer, LoadingParentContainer } from '../loading/loading.styles'

const LoadingComponent = ({ mode, loadingref, position, shape, color, extendClass, margin, ...props }) => {
  const _className = `${props.className || ''} ${extendClass || ''}`
  if(shape === 'dot') {
      return (  <LoadingParentContainer className={_className} mode={mode} color={color} ref={loadingref} position={position} margin={margin} {... props} >
                  <div></div>
                  <div></div>
                  <div></div>
                </LoadingParentContainer>  )
  }else {
    return <LoadingContainer className={_className} mode={mode} color={color} ref={loadingref} position={position} margin={margin} {... props} ></LoadingContainer>
  }
}

LoadingComponent.propTypes = {
  mode: PropTypes.string, 
  extendClass: PropTypes.string,
  shape: PropTypes.string, 
  color: PropTypes.string,
  position: PropTypes.string,
  loadingref: PropTypes.any, 
}

export default LoadingComponent