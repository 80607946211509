import React from 'react'
import { useQuery } from "@apollo/client"

import { GET_TYPE_LIST } from '../../../../config/graphql'
import LoadingComponent from '../../../../components/loading/loading.component'
import SelectComponent from '../../../../components/select/select.component'

export const TypeList = ({ evChangeType, currentType, errorRegister }) => {
  const { loading, error, data } = useQuery(GET_TYPE_LIST)
  if(loading) return <LoadingComponent mode='mediumclear'/>
  if(error) return `Error!: ${error}`
  let DefaultValue = { value: '', label: 'Please select'},
    TypeOptions = data ? data.profileTypes.map(list => {
      return { value: list.id, label: list.name}
    }) : []
  TypeOptions = [DefaultValue].concat(TypeOptions)
  return (
    <SelectComponent plugin='react-select' options={TypeOptions} defaultValue={(currentType) ? { value: currentType.id, label: currentType.name } : DefaultValue} onChange={evChangeType} isError={(errorRegister && errorRegister['typeId'])}/>
  )
}