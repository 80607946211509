import { css } from 'styled-components'

export const clearList = css`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const responseWidth = css `
  width: 100%;  
  @media only screen and (min-width: 1024px) {
    min-width: 994px;
    max-width: 994px;
  }
  @media only screen and (min-width: 1280px) {
    min-width: 1031px;
    max-width: 1031px;
  }
  @media only screen and (min-width: 1440px) {
    min-width: 1112px;
    max-width: 1112px;
  }
  @media only screen and (min-width: 1680px) {
    min-width: 1336px;
    max-width: 1336px;
  }
`

export const elipsis = css `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 100%;
  width: auto;
`
