import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from "@apollo/client"

import PopupComponent from '../../../components/popup/popup.component'
import InputComponent from '../../../components/input/input.component'
import ButtonComponent from '../../../components/button/button.component'
import LoadingComponent from '../../../components/loading/loading.component'
import ErrorComponent from '../../../components/error/error.component'

import { ListBodyContainer, ListContainer, ListTitleContainer, ListContentContainer } from '../admin.styles'
import { TypeList } from './partials/type-list'
import { StatusList } from './partials/status-list'
import { CompanySelect } from './partials/company-select'
import { GET_COMPANY_LIST, MUTATION_UPDATE_PROFILE } from '../../../config/graphql'

export const PopupEditProfile = ({ body, height, width, isOpen, isOpenContainer, evTooglePopup, dataDetail }) => {

  const [ isFirstLoad, setIsFirstLoad ] = useState(false)
  const [ profileId, setProfileId ] = useState('')
  const [ companyId, setCompanyId ] = useState('')
  const [ companyName, setCompanyName ] = useState('')
  const [ typeId, setTypeId ] = useState('')
  const [ statusId, setStatusId ] = useState('')
  const [ errors, setErrors ] = useState(null)

  const { loading: loadingGetCompany, data: dataCompany } = useQuery(GET_COMPANY_LIST)
  const [updateProfile, { loading: loadingMutationProfile }] = useMutation(MUTATION_UPDATE_PROFILE, {
    variables: { input : { id: profileId, companyId: companyId, typeId: typeId, statusId: statusId } },
    onCompleted(data) {
      console.log(data, 'response data')
      evTooglePopup()
      window.location.reload()
    },
    onError(error) {
      console.log(error)
    }
  })

  useEffect(() => {
    evUpdate()
  })

  const evUpdate = () => {
    if(!isFirstLoad) {
      setProfileId(dataDetail.id)
      setCompanyId(parseInt(dataDetail.company?.id))
      setCompanyName(dataDetail.company?.name)
      setTypeId(parseInt(dataDetail.type?.id))
      setStatusId(parseInt(dataDetail.status?.id))
      setIsFirstLoad(true)
    }
    if(!loadingGetCompany) evCurrentCompany()
  }

  const evCurrentCompany = () => {
    const companyRef = document.getElementsByClassName('_refCompany')
    companyRef[0].value = companyName || ''
  }
  
  const evChangeType = (e) => {
    setTypeId(parseInt(e.value))
    if(errors && errors['typeId']) {
      delete errors.typeId
      setErrors(errors)
    }
  }

  const evChangeStatus = (e) => {
    setStatusId(parseInt(e.value))
    if(errors && errors['statusId']) {
      delete errors.statusId
      setErrors(errors)
    }
  }

  const evCloseDropdown = (e) => {
    const ComponentContainerRef = document.getElementsByClassName('_refComponentContainer')
    ComponentContainerRef[0].classList.remove('is-active')
  }

  const evSaveData = () => {
    if(profileId && companyId && typeId && statusId) updateProfile()
    else {
      let newErrors = {}
      if(!companyId) newErrors.companyId = true
      if(!typeId) newErrors.typeId = true
      if(!statusId) newErrors.statusId = true
      setErrors(newErrors)
    }
  }

  return(
    <PopupComponent body={body} height={height} width={width} isOpen={isOpen} isOpenContainer={isOpenContainer} evTooglePopup={evTooglePopup}>
      <div aria-label='head'>Edit Profile</div>
      <div aria-label='body'>
        <ListBodyContainer>
          <ListContainer>
            <ListTitleContainer>Profile</ListTitleContainer>
            <ListContentContainer>
              <InputComponent type="text" value={dataDetail.name+" ("+dataDetail.id+")"} disabled/>
              <InputComponent type="hidden" name="profile" value={dataDetail.id} />
            </ListContentContainer>
          </ListContainer>
          <ListContainer>
            <ListTitleContainer isRequired={true}>Company</ListTitleContainer>
            <ListContentContainer>
            {
              (loadingGetCompany) ? <LoadingComponent mode='mediumclear'/>
              : <CompanySelect data={dataCompany} evCloseDropdown={evCloseDropdown} setCompanyId={setCompanyId} setCompanyName={setCompanyName} errorRegister={errors} setErrors={setErrors}/> 
            }
            {(errors && errors['companyId']) && <ErrorComponent>Company is required</ErrorComponent> }
            </ListContentContainer>
          </ListContainer>
          <ListContainer>
            <ListTitleContainer isRequired={true}>Type</ListTitleContainer>
            <ListContentContainer>  
              <TypeList evChangeType={evChangeType} currentType={dataDetail.type} errorRegister={errors}/>
              {(errors && errors['typeId']) && <ErrorComponent>Type is required</ErrorComponent> }
            </ListContentContainer>
          </ListContainer>
          <ListContainer>
            <ListTitleContainer isRequired={true}>Status</ListTitleContainer>
            <ListContentContainer>
              <StatusList evChangeStatus={evChangeStatus} currentStatus={dataDetail.status} errorRegister={errors}/>
              {(errors && errors['statusId']) && <ErrorComponent>Status is required</ErrorComponent> }
            </ListContentContainer>
          </ListContainer>
        </ListBodyContainer>
      </div>
      <div aria-label='foot'>
        <ButtonComponent mode='red' onClick={evSaveData}>Save 
        {
          (loadingMutationProfile) ? 
          <LoadingComponent extendClass='_refLoading' shape='dot' color='#ffffff' />
          : null 
        }
        </ButtonComponent>
      </div>
    </PopupComponent>
  )
}