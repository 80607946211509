import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ProtectedRoute } from './pages/login/auth/protected.route';
import LoginIndex from './pages/login/index'
import AdminIndex from './pages/admin/index'

function App() {
  return (
    <BrowserRouter basename={'/'}>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={LoginIndex}/>
        <ProtectedRoute path={`${process.env.PUBLIC_URL}/dashboard/admin`} component={AdminIndex}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
