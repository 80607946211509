import React, { useState} from 'react'
import { useMutation } from "@apollo/client"

import InputComponent from '../../../../components/input/input.component'
import { SearchDropdownContainer, DropdownListContainer, DropdownButtonContainer } from '../../admin.styles'
import { MUTATION_CREATE_COMPANY } from '../../../../config/graphql'

export const CompanySelect = ({ evCloseDropdown, data, setCompanyId, setCompanyName, errorRegister, setErrors }) => {

  const [ searchCompany, setSearchCompany ] = useState('')

  const filteredData = data.companies.filter((dataObj) => 
    (dataObj.name.toLowerCase().indexOf(searchCompany.toLowerCase()) !== -1)
  )

  const evChangeCompany = (e) => {
    const ComponentContainerRef = document.getElementsByClassName('_refComponentContainer')

    if(ComponentContainerRef.length && e.target.value.length) {
      const targetEl = ComponentContainerRef[0]
      targetEl.classList.add('is-active')
    } else {
      const targetEl = ComponentContainerRef[0]
      targetEl.classList.remove('is-active')
      setCompanyId('')
      setCompanyName('')
    }

    setSearchCompany(e.target.value)
  }

  const evSetCompany = (e) => {
    const companyRef = document.getElementsByClassName('_refCompany')
    const componentContainerRef = document.getElementsByClassName('_refComponentContainer')
    const companyName = e.target.getAttribute('data-name')
    const companyId = e.target.getAttribute('data-id')

    componentContainerRef[0].classList.remove('is-active')
    companyRef[0].value = companyName
    setCompanyId(parseInt(companyId))
    setCompanyName(companyName)

    if(errorRegister && errorRegister['companyId']) {
      delete errorRegister.companyId
      setErrors(errorRegister)
    }
  }

  const [createCompany] = useMutation(MUTATION_CREATE_COMPANY, {
    variables: { input : { name: searchCompany } },
    onCompleted(data) {
      setCompanyId(parseInt(data.createCompany.company.id))
      setCompanyName(searchCompany)
      
      if(errorRegister && errorRegister['companyId']) {
        delete errorRegister.companyId
        setErrors(errorRegister)
      }
    },
    onError(error) {
      console.log(error)
    }
  })

  const evAddNew = () => {
    createCompany()
    evCloseDropdown()
  }

  return (
    <div>
      <InputComponent type="text" onChange={evChangeCompany} placeholder="Select company name" className="_refCompany" isError={(errorRegister && errorRegister['companyId'])}/>
      <SearchDropdownContainer className='_refComponentContainer'>
        <DropdownListContainer>
          {filteredData.map((company, idx) => {
              return (
                <div key={idx} onClick={evSetCompany} data-id={company.id} data-name={company.name}>{company.name}</div>
              )
            })
          }
        </DropdownListContainer>
        <DropdownButtonContainer onClick={evAddNew}>+ Add new "{searchCompany}"</DropdownButtonContainer>
      </SearchDropdownContainer>
    </div>
  )
}