import styled from 'styled-components'

/* function */
const getErrorModifiedStyle = ({ mode, justifyContent }) => {
  let modifiedStyle=``
  if(mode === 'api') modifiedStyle += `
    color: var(--ctrl-color);
    width: auto;
    padding: .5em .5em;
    margin: 10px;
    font-weight: 400;
  `
  if(justifyContent) modifiedStyle += `justify-content: ${justifyContent}`
  return modifiedStyle
}

/* component */
export const ErrorContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-columns: min-content;
  grid-gap: 4px;
  align-items: baseline;
  color: #fc5c64;
  align-content: center;
  width: 100%;
  text-align: left;
  font-size: 13px;
  line-height: 1.3;
  margin-top: 4px;
  font-weight: 400;
  ${getErrorModifiedStyle}
`
/* component */
