import React from 'react'
import loadable from '@loadable/component'

import { AdminContainer, LayoutContentContainer } from '../admin.styles'
import { TabsContainer, TabsWrapper, TabsHeader, TabsList, TabsItem, TabsContent } from '../../../assets/stylesheets/shares.styles'
const ProfileAdmin = loadable(() => import('./tabs/profile.admin'))
const AnnouncementAdmin = loadable(() => import('./tabs/announcement.admin'))

const AdminPartial = ({ activeTab, evClickTabDetail, evOpenPopup, evCreateMessage }) => {
  let sectionEl
  switch(activeTab) {
    case 'profile':
      sectionEl = <ProfileAdmin evOpenPopup={evOpenPopup} />
      break
    case 'announcement':
      sectionEl = <AnnouncementAdmin evCreateMessage={evCreateMessage}/>
      break
    default:
      sectionEl = <ProfileAdmin evOpenPopup={evOpenPopup} />
  }

  return (
    <AdminContainer>
      <TabsContainer>
        <TabsWrapper mode='admin' className='_refTabsHeadContainer'>
          <TabsHeader>
            <TabsList>
              <TabsItem data-label='profile' isActive={activeTab === 'profile'} onClick={evClickTabDetail}>Profile Manager</TabsItem>
              <TabsItem data-label='announcement' isActive={activeTab === 'announcement'} onClick={evClickTabDetail}>Announcement</TabsItem>
            </TabsList>
          </TabsHeader>
        </TabsWrapper>
        <TabsContent>
          <LayoutContentContainer>
            {sectionEl}
          </LayoutContentContainer>
        </TabsContent>
      </TabsContainer>
    </AdminContainer>
  )
}

export default AdminPartial;