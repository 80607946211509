import styled from 'styled-components'
import { responseWidth } from './bases.style'

const getTabsItemModFunc = ({isActive}) => {
  let modStyle = ``
  if(isActive) modStyle += `
    color: #FF585C;
    &::after {
      transform: scale(1);
    }
  `
  return modStyle
}

const getTabsContainerModFunc = ({mode}) => {
  let modStyle = ``
  if (mode === 'admin') modStyle += `
    padding: 0 16px;
  `
  return modStyle
}

const getTabsWrapperModFunc =({mode}) =>{
  let modStyle = ``;
  if(mode === 'admin') modStyle += `
    background-color: #fff;
    >article {
      min-width: auto;
      max-width: none;
      width: 100%;
    }
  `
  return modStyle;
}

export const TabsContainer = styled.section` 
  width: 100%;
  ${getTabsContainerModFunc}
`

export const TabsWrapper = styled.section`
  width: 100%;
  overflow: auto;
  &.is-sticky {
    position: fixed; 
    top: 53px;
    z-index: 9;
    background-color:#FFF;
    > article {
      position: relative;
      z-index: 10;
    }
    & + div {
      margin-top: 40px;
    }
    @media only screen and (max-width: 767px) {
      top: 0;
    }
    @media only screen and (min-width: 768px) {
      &:before {
        position: fixed;
        content: '';
        height: 42px;
        top: 53px;
        background: #fff;
        z-index: 1;
        width: 100vw;
        left: 0;
      }
    }
  }
  ${getTabsWrapperModFunc}
`

export const TabsHeader = styled.article`
  @media only screen and (min-width: 1024px) {
    ${responseWidth}
    margin: 0 auto;
  }
`

export const TabsList = styled.div` 
  /* padding-left: 24px; */
  padding: 0 16px;
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  grid-auto-columns: max-content;
  width: 100%;
`

export const TabsItem = styled.div`
  color: #000;
  cursor: pointer;
  letter-spacing: 0.6px;
  position: relative;
  line-height: 15px;
  font-size: 14px;
  padding: 9px;
  font-weight: 300;
  @media only screen and (min-width: 1024px) {
    font-size: 16px;
    font-weight: 500;
    padding: 16px 15px 11px 15px;
  }
  &::after {
    content: '';
    position: absolute;
    background-color: #FF585C;
    left: 0;
    height: 2px;
    bottom: 0;
    right: 0;
    width: 100%;
    transition: all 250ms ease 0s;
    transform: scale(0);
  }
  ${getTabsItemModFunc}
`

export const TabsContent = styled.div` 
  background-color: #FFF;
  min-height: calc(100vh - 400px);
`

export const PopupRowGroup = styled.div` 
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px;
`

export const PopupColumnGroup = styled.div` 
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  justify-content: left;
  font-size: 14px;
`

export const FormContainer = styled.form.attrs(props => ({
  name: (props.name) ? props.name : 'form', 
  autoComplete: (props.autocomplete) ? props.autocomplete : 'off' 
}))``